import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from "axios";
import "../styles.css";
import BASE_URL from "../config"; // URL сервера
import { TextField, Button, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
const PromptInput = () => {
    const [prompt, setPrompt] = React.useState("");
    const [age, setAge] = React.useState("4");
    const [voice, setVoice] = React.useState("Alloy");
    const [style, setStyle] = React.useState("3D");
    const [isLoading, setIsLoading] = React.useState(false);
    const [showLimitModal, setShowLimitModal] = React.useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!prompt.trim()) {
            console.error("Ask mahaon anything");
            return;
        }

        setIsLoading(true);
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${BASE_URL}/api/save-prompt/`, {
                text: prompt,
                tape: "default",
                age: age,
                style_img: style,
                voice_type: voice,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 201) {
                console.log("Prompt successfully saved!");
                setPrompt("");
                window.location.reload();
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setShowLimitModal(true);
            } else {
                console.error("❌ Network or Unknown Error:", error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };
// display: flex;align-items: center;gap: 10px;width: 100%;flex-direction: row;
    return (
        <div className="all_input">
            <form
                className="prompt-input-wrapper prompt-container"
                onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    width: '100%',
                    flexDirection: 'row',
                    height: '100%', // Робимо висоту форми однаковою для всіх елементів
                }}
            >
                <TextField
                    label="Ask mahaon anything"
                    type="search"
                    variant="standard"
                    fullWidth
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    multiline
                    minRows={1}
                    maxRows={5}
                    sx={{
                        flexGrow: 1,
                        '& .MuiInputBase-root': {
                            padding: '10px',
                            borderRadius: '20px',
                            display: 'flex',
                            alignItems: 'center',
                        },
                    }}
                />
            <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                sx={{
                    borderRadius: '20px',
                    backgroundColor: 'rgba(77,212,23,0.54)',
                    color: 'white',
                    padding: '0 20px', // Фіксований padding для тексту
                    height: '100%', // Робимо кнопку по висоті рівною полю вводу
                    minWidth: '120px', // Мінімальна ширина кнопки
                    maxWidth: '150px', // Щоб кнопка не розширювалася занадто
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '14px', // Адаптивний розмір тексту
                    whiteSpace: 'nowrap', // Запобігає переносу тексту
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', // Обрізає текст, якщо не влазить
                    lineHeight: '4.75',
                    fontWeight: 700
                }}
            >
                GENERATE
            </Button>
            </form>

            <div className="mui-dropdown-container"
                 style={{display: 'flex', gap: '10px', justifyContent: 'center', flexWrap: 'wrap'}}>
                <FormControl variant="filled" sx={{minWidth: 100, flex: 1}} className="rounded-select">
                    <InputLabel>Age</InputLabel>
                    <Select value={age} onChange={(e) => setAge(e.target.value)}>
                        {[...Array(9)].map((_, i) => (
                            <MenuItem key={i} value={(i + 4).toString()}>{i + 4}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="filled" sx={{minWidth: 150, flex: 1, borderRadius: '20px',}}
                             className="rounded-select">
                    <InputLabel>Voice</InputLabel>
                    <Select value={voice} onChange={(e) => setVoice(e.target.value)}>
                        {['Alloy', 'Echo', 'Fable', 'Onyx', 'Shimmer'].map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="filled" sx={{minWidth: 150, flex: 1}} className="rounded-select">
                    <InputLabel>Style</InputLabel>
                    <Select value={style} onChange={(e) => setStyle(e.target.value)}>
                        {["3D", "Cartoon", "Real"].map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {showLimitModal && (
                <div className="modal_limit">
                    <div className="modal-content_limit">
                        <button className="modal-close_limit" onClick={() => setShowLimitModal(false)}>✖</button>
                        <p>Oops! This feature is available for subscribers only. Unlock AI-generated videos now!</p>
                        <button
                            className="close-button_limit"
                            onClick={() => window.open('https://info.mahaonai.com/#pricing', '_blank')}
                        >
                            Subscribe
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PromptInput;

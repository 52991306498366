import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

export default function PaymentPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const hash = location.hash.substring(1);
  const hasRequested = useRef(false);

  // Функція для встановлення cookie
  const setLocalStorage = (name, value, days) => {
    const item = {
      value: value,
      expiry: days ? new Date().getTime() + days * 24 * 60 * 60 * 1000 : null
    };
    localStorage.setItem(name, JSON.stringify(item));
  };

  // Запит на створення підписки
  const createSubscription = async (token) => {
    console.log('Creating subscription...');
    setLoading(true);
    try {
      const response = await axios.post(
        'https://bec.agentai.space/api/create-subscription/',
        { plan_name: hash },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const subscriptionData = response.data;
      console.log('Response from backend:', subscriptionData);

      // Якщо вже є активна підписка, повідомляємо і редиректимо на головну
      if (subscriptionData.error && subscriptionData.error.includes("active subscription")) {
        alert("You already have an active subscription. Redirecting to the homepage...");
        setTimeout(() => navigate("/"), 1000); // Редирект через 1 секунди
        return;
      }

      // Якщо є посилання на оплату, переходимо на нього
      if (subscriptionData.payment_url) {
        window.location.href = subscriptionData.payment_url;
      } else {
        throw new Error('Payment URL is missing from the server response');
      }
    } catch (error) {
      console.error('Error creating subscription:', error);
      alert(`Error creating subscription: ${error.response?.data?.message || error.message}`);
      setTimeout(() => navigate("/"), 3000); // Якщо помилка, редиректимо на головну
    } finally {
      setLoading(false);
    }
  };

  // Перевірка авторизації та виклик createSubscription
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      if (hash) {
        setLocalStorage('selected_plan', hash, 1);
      }
      navigate('/login');
    } else {
      if (!hasRequested.current) {
        hasRequested.current = true;
        createSubscription(token);
      }
    }
  }, [navigate, hash]);

  return (
    <div className="max-w-lg mx-auto mt-10 p-4 text-center">
      <h1 className="text-xl font-bold mb-4">Processing your subscription...</h1>
      <p className="mb-4">You will be redirected to Monobank shortly to complete the payment.</p>
      {loading && <p>Loading...</p>}
    </div>
  );
}

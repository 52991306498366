import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Start from './Start';
import Privacy_policy from './Privacy_policy';
import Terms_of_use from './Terms_of_use';
import Oplata from './Oplata';
import Oplatatest from './Oplatatest';
import { GoogleOAuthProvider } from '@react-oauth/google';

const RedirectToHome = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/home') {
      window.location.replace('/');
    }
  }, [location.pathname]);
  return null;
};



const App = () => {
  const [showExitOverlay, setShowExitOverlay] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    console.log("User-Agent:", userAgent);

    if (/FBAN|FBAV|Instagram|Telegram/i.test(userAgent)) {
      console.log("Сайт відкритий у WebView");
      setShowExitOverlay(true); // Вмикаємо кнопку виходу

      setTimeout(() => {
        window.open(
          `https://${window.location.hostname}${window.location.pathname}${window.location.search}`,
          '_blank'
        );

      }, 1500);
    } else {
      console.log("Сайт відкритий у стандартному браузері");
    }
  }, []);

  useEffect(() => {
    const currentHost = window.location.hostname;
    const protocol = window.location.protocol;

    if (protocol !== 'https:') {
      // window.location.href = `https://${currentHost}${window.location.pathname}${window.location.search}`;
    }
    if (
      currentHost !== 'localhost' &&
      currentHost !== '127.0.0.1' &&
      currentHost !== 'mahaonai.com'
    ) {
      const currentPath = window.location.pathname + window.location.search;
      window.location.href = `https://mahaonai.com${currentPath}`;
    }
  }, []);

    // **Hotjar Tracking Code**
  useEffect(() => {
    (function(h, o, t, j, a, r) {
      h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: 5341657, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, []);

  return (
    <AuthProvider>
      <GoogleOAuthProvider clientId='372972581140-1s084rmms362e0p58b4k3miusfgnt4rm.apps.googleusercontent.com'>
        <Router>
          <RedirectToHome />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Start />} />
            <Route path="/terms_of_use" element={<Terms_of_use />} />
            <Route path="/privacy_policy" element={<Privacy_policy />} />
            {/*<Route path="/price" element={<Start />} />*/}
            <Route path="/terms_of_гse" element={<Start />} />
            {/*<Route path="/price" element={<Start />} />*/}
            <Route path="/pricetest" element={<Oplatatest />} />
            <Route path="/price" element={<Oplatatest />} />
          </Routes>
        </Router>
        {showExitOverlay && <ExitWebViewOverlay />} {/* Оверлей рендериться */}
      </GoogleOAuthProvider>
    </AuthProvider>
  );
};

const ExitWebViewOverlay = () => {
  const exitWebView = () => {
    console.log("Користувач натиснув на кнопку виходу");

    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      console.log("Відкриття у Google Chrome на iOS...");
      window.location.href = `googlechrome://${window.location.hostname}${window.location.pathname}${window.location.search}`;

      setTimeout(() => {
        // Якщо Chrome не встановлений, відкриваємо в Safari
        window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
      }, 1500);
    }

    else if (/Android/i.test(navigator.userAgent)) {
      console.log("Відкриття у Google Chrome на Android...");
      window.location.href = `intent://${window.location.hostname}${window.location.pathname}${window.location.search}#Intent;scheme=https;package=com.android.chrome;end;`;

      setTimeout(() => {
        // Якщо Chrome не встановлений, відкриваємо у звичайному браузері
        window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
      }, 1500);
    }

    else {
      console.log("Ручне відкриття у стандартному браузері...");
      window.open(`https://${window.location.hostname}${window.location.pathname}${window.location.search}`, '_blank');
    }
  };

  return (
    <div
      onClick={exitWebView}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
        zIndex: 9999,
        cursor: 'pointer',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      🚀 <br />
      <span style={{ fontSize: '24px' }}>This site does not work in WebView</span> <br />
      <span style={{ fontSize: '18px' }}>Tap to open in Google Chrome</span>
    </div>
  );
};

export default App;

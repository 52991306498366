import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./styles.css";
import 'antd/dist/reset.css';

// Імпорт HTML-контенту
import privacyPolicyHTML from "./data/privacyPolicyContent";

const PrivacyPolicy = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="content">
        <div className="terms-container">
          {/* Вставка HTML-коду */}
          <div dangerouslySetInnerHTML={{ __html: privacyPolicyHTML }} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;

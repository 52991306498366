import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles.css";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) return;

    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.get("https://bec.mahaonai.com/api/subscription/status/", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubscription(response.data);
      } catch (error) {
        console.error("Error fetching subscription:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, [token]);

  return (
    <nav className="navbar">
      {/* Логотип */}
      <div className="logoty"></div>

      {/* Бургер-кнопка */}
      <div className={`burger ${menuOpen ? "open" : ""}`} onClick={() => setMenuOpen(true)}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Меню */}
      <div className={`menu ${menuOpen ? "active" : ""}`}>
        {/* Логотип всередині меню */}
        <div className="menu-header">
          <div className="logoty cloce_logoty"></div>
          <div className="close-menu" onClick={() => setMenuOpen(false)}>×</div>
        </div>

        <a href="/" onClick={() => setMenuOpen(false)}>Create</a>
        <a href="/" onClick={() => setMenuOpen(false)}>About us</a>
        <a href="/" onClick={() => setMenuOpen(false)}>Contacts</a>
        <a href="/" onClick={() => setMenuOpen(false)}>Community</a>
        <a href="/" onClick={() => setMenuOpen(false)}>My profile</a>
        <a href="/login" onClick={() => setMenuOpen(false)}>Exit</a>

        {loading ? (
          <button className="subscribe-button">Loading...</button>
        ) : subscription ? (
          <button className="subscribe-button">
            <span className="subscribe-icon">✨</span> {subscription.remaining_videos} Videos
          </button>
        ) : (
          <button className="subscribe-button" onClick={() => (window.location.href = "https://info.mahaonai.com/#pricing")}>
            <span className="subscribe-icon">✨</span> Subscribe
          </button>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

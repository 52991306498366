import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import axios from "axios";
import "antd/dist/reset.css";
import { Progress, Flex } from "antd";
import "../styles.css";
import { useNavigate } from "react-router-dom"; // Додаємо навігацію

const twoColors = {
  '0%': '#108ee9',
  '100%': '#87d068',
};

const conicColors = {
  '0%': '#87d068',
  '50%': '#ffe58f',
  '100%': '#ffccc7',
};

const VideoList = ({ videos }) => {
  const [videoList, setVideoList] = useState(videos);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const navigate = useNavigate(); // Ініціалізуємо навігацію

  const cleanPath = (path) => (path.startsWith("/usr/src/app") ? path.replace("/usr/src/app", "") : path);

  const handleVideoClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };

  const closeModal = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      setSelectedVideo(null);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setSelectedVideo(null);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const handleDownload = async () => {
    if (!selectedVideo) return;

    // Видалення суфікса після підкреслення
    const baseName = selectedVideo.replace(/_[^/]*\.mp4$/, ".mp4");

    // Додавання суфікса для водяного знака
    const downloadUrl = `https://bec.mahaonai.com${baseName.replace(".mp4", "_watermark.mp4")}`;

    try {
      // Перевірка, чи існує відео з водяним знаком
      await axios.head(downloadUrl);
      downloadFile(downloadUrl);
    } catch (error) {
      console.warn("Відео з ватермаркою не знайдено, завантажується оригінал.");
      downloadFile(`https://bec.mahaonai.com${selectedVideo}`);
    }
  };

  const downloadFile = async (url) => {
    try {
      const response = await axios.get(url, {
        responseType: "blob", // Завантаження у форматі Blob
      });

      const blob = new Blob([response.data], { type: "video/mp4" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = url.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Помилка при завантаженні відео:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("https://bec.mahaonai.com/api/user/prompts", {
          headers: { Authorization: `Bearer ${token}` },
        });

        const updatedVideos = response.data.map((video) => ({
          id: video.id,
          title: video.title || "Untitled",
          videoUrl: video.video || null,
          videoText: video.text || "No description",
          thumbnail: video.thumbnail_url || null,
          views: video.views || "0",
          time_ago: video.time_ago || "Just now",
          status: video.status || "pending",
          progress: video.progress || 0,
        }));

        setVideoList(updatedVideos);
      } catch (error) {
        console.error("Error updating videos:", error);
        if (error.response && error.response.status === 401) {
          console.warn("404 detected: Clearing tokens and redirecting to login...");
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          navigate("/login"); // Редірект на сторінку логіну
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="video-list_new">
      <h2>Your Creations</h2>
      <div className="video-grid_new">
        {videoList.length > 0 ? (
          videoList.map((video, index) => (
            <div key={index} className="video-card_new" onClick={() => handleVideoClick(video.videoUrl)}>
              {video.status === "pending" || video.status === "in_progress" ? (
                <div className="video-loading">
                  <Flex gap="small" wrap>
                    <Progress type="circle" percent={video.progress} strokeColor={twoColors} />
                  </Flex>
                </div>
              ) : (
                <div className="video-wrapper_new">
                  <ReactPlayer
                    url={`https://bec.mahaonai.com${video.videoUrl}`}
                    controls={false}
                    width="100%"
                    height="100%"
                    className="react-player"
                    light={video.thumbnail ? `https://bec.mahaonai.com${cleanPath(video.thumbnail)}` : true}
                  />
                  <div className="video-description">
                    <p>{video.videoText.length > 100 ? video.videoText.slice(0, 100) + "..." : video.videoText}</p>
                    {/*<p>{video.videoText}</p>*/}
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
            <p>No videos found</p>
        )}
      </div>

      {selectedVideo && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={() => setSelectedVideo(null)}>
              ✖
            </button>
            <button className="download-button" onClick={handleDownload}>

            </button>
            <ReactPlayer
                url={`https://bec.mahaonai.com${selectedVideo}`}
                controls
                playing
                width="100%"
                height="100%"
                className="fullscreen-video"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoList;

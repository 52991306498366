import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import PromptInput from "./components/PromptInput";
import VideoList from "./components/VideoList";
import axios from "axios";
import BASE_URL from "./config"; // Використовуємо змінну для API
import "./styles.css";
import { useNavigate } from "react-router-dom"; // Додаємо useNavigate для редіректу
import { Progress } from 'antd';
import 'antd/dist/reset.css';

// Функція для отримання значення з localStorage і його видалення
const getAndRemoveLocalStorage = (name) => {
  const itemStr = localStorage.getItem(name);
  if (!itemStr) return null;

  try {
    const item = JSON.parse(itemStr);
    localStorage.removeItem(name); // Видаляємо перед використанням
    return item;
  } catch (error) {
    console.error(`Error parsing localStorage key "${name}":`, error);
    localStorage.removeItem(name); // Видаляємо у разі помилки
    return null;
  }
};

const Start = () => {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); // Використовуємо useNavigate для навігації

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login"); // Редірект на сторінку логіну, якщо немає токена
      return;
    }

    // Перевіряємо, чи є збережений план підписки
    const storedPlan = getAndRemoveLocalStorage("selected_plan");
    if (storedPlan && storedPlan.value) {
      console.log("Redirecting to:", `/pricetest#${storedPlan.value}`);
      navigate(`/pricetest#${storedPlan.value}`);
      return;
    }

    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/user/prompts`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(response);
        const fetchedVideos = response.data.map((video) => ({
          id: video.id,
          title: video.title || "Untitled",
          videoUrl: video.video || null,
          videoText: video.text || "No description",
          thumbnail: video.thumbnail_url || null,
          views: video.views || "0",
          time_ago: video.time_ago || "Just now",
          status: video.status || "pending",
          progress: video.progress || "0",
        }));

        setVideos(fetchedVideos);
      } catch (error) {
        console.error("Error fetching videos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="app">
      <div className="background-blobs"></div>
      <Navbar />
      <div className="content">
        <PromptInput />
        {isLoading ? <p>Loading videos...</p> : <VideoList videos={videos} />}
      </div>
      <Footer />
    </div>
  );
};

export default Start;

import React from "react";
import "./Footer.css"; // Імпортуємо CSS стилі
import { Instagram, Youtube, Mail, Music } from "lucide-react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">

        {/* Логотип + Опис */}
        <div className="footer-section logo-section">
          <div className="footer-logo"></div>
          <p className="footer-description">
            Mahaon is a platform that provides instant video answers to children’s questions using AI.
            Popular topics, interactive features, and easy access make Mahaon a reliable tool for parents
            to support their children’s curiosity and learning.
          </p>
          <p className="footer-created">Created by Mahaon</p>
        </div>

        {/* Навігація */}
        <div className="footer-section links-section">
          <h3>About us</h3>
          <ul className="footer-links">
            <li><a href="/subscription">Subscription</a></li>
            <li><a href="/privacy">Privacy & Terms</a></li>
            <li><a href="/contact">Contact us</a></li>
            <li><a href="/terms_of_use">Terms of use</a></li>
            <li><a href="/privacy_policy">Privacy policy</a></li>
          </ul>
        </div>

        {/* Соцмережі */}
        <div className="footer-section social-section">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.instagram.com/mahaon.app/"><Instagram size={20}/></a>
            <a href="https://www.youtube.com/@mahaonapp"><Youtube size={20}/></a>
            <a href="https://www.tiktok.com/@mahaon.ai"><Music size={20}/></a>
            <a href="mailto:mahaonapp@gmail.com?subject=Feedback&body=Hello, I have some feedback...">
              <Mail size={20}/>
            </a>
          </div>
        </div>

        {/* Кнопки */}
        <div className="footer-section buttons-section">
          <button className="footer-button"
            onClick={() => window.open('https://info.mahaonai.com/#pricing', '_blank')}>
            Subscribe
          </button>
          <button className="footer-button"
            onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLScgbi2cs3_aTB2EvzUYxpDLx-hRaCXB5N1KceDWCRQBaApJtA/viewform', '_blank')}>
            Send feedback
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

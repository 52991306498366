import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./styles.css";
import 'antd/dist/reset.css';

// Імпорт HTML-контенту
import Terms_of_use_Html from "./data/Terms_of_use";

const Terms_of_use = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="content">
        <div className="terms-container">
          {/* Вставка HTML-коду */}
          <div dangerouslySetInnerHTML={{ __html: Terms_of_use_Html }} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms_of_use;

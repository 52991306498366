import React from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOutlined, FacebookFilled } from '@ant-design/icons';
import { useAuth } from '../AuthContext';
import './Register.css';
import logoImage from '../img/mahaon.png';
import {GoogleLogin} from "@react-oauth/google";

const Register = () => {
  const { register } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();
const onGoogleLoginSuccess = async (response) => {
    console.log("Google token received:", response.credential);

    try {
        console.log("Sending Google token to backend...");
        const backendResponse = await fetch('https://bec.mahaonai.com/api/google-login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: response.credential }),
        });
        console.log("Sended Google token to backend...");
        console.log(backendResponse)
        if (backendResponse.ok) {
            const data = await backendResponse.json();
            console.log("Backend response for google-login:", data);

            // Перевірка, чи токени присутні в відповіді
            if (data.access_token && data.refresh_token) {
                // Збереження токенів у localStorage
                localStorage.setItem('token', data.access_token);
                localStorage.setItem('refreshToken', data.refresh_token);
                console.log("Tokens saved to localStorage.");
                navigate('/login');
            } else {
                console.error("Access or Refresh token missing in backend response:", data);
            }
        } else {
            const error = await backendResponse.json();
            console.error("Google login failed:", error);
        }
    } catch (error) {
        console.error("Error during Google login process:", error);
    }
};
  const onFinish = async (values) => {
    try {
      const payload = {
        username: values.email,
        email: values.email,
        password: values.password,
      };

      await register(payload);
      message.success('Registration successful! Redirecting...');
      navigate('/');
    } catch (error) {
      message.error('Registration failed. Please try again.');
      console.error('Registration failed:', error);
    }
  };

  return (
    <div className="register-container">
        <div className="register-form-container">
            <div className="butterfly butterfly-1"></div>
            <div className="butterfly butterfly-2" style={{top: "61%"}}></div>
            <img src={logoImage} alt="MAHAON" className="logot"/>
            <h2>Sign Up</h2>
            <Form
                form={form}
                name="register"
                className="register-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >

                <Form.Item
                    name="email"
                    rules={[
                        {required: true, message: 'Please input your email!'},
                        {type: 'email', message: 'Please enter a valid email!'}
                    ]}
                >
                    <Input placeholder="Email"/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{required: true, message: 'Please input your password!'}]}
                >
                    <Input.Password placeholder="Password"/>
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    dependencies={['password']}
                    rules={[
                        {required: true, message: 'Please confirm your password!'},
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Confirm Password"/>
                </Form.Item>

                <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[{validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement'))}]}
                >
                    <Checkbox>I agree to the Terms & Conditions</Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="register-button">
                        Create Account
                    </Button>
                </Form.Item>
            </Form>

            <div className="social-login">
                <div className="social-login">
                    <span>or continue with</span>
                    <div className="social-buttons" style={{zIndex: "9999"}}>
                        <GoogleLogin
                            clientId="372972581140-1s084rmms362e0p58b4k3miusfgnt4rm.apps.googleusercontent.com"
                            onSuccess={onGoogleLoginSuccess}
                            redirectUri="https://agentai.space"
                        />
                    </div>
                </div>
            </div>
            <div className="login-link" style={{zIndex: "9999"}}>
                 <Link to="/privacy_policy">Privacy  policy</Link> | <Link to="/Terms_of_use">Terms of use</Link>
            </div>
            <div className="login-link" style={{zIndex: "9999"}}>
                Already have an account? <Link to="/login">Sign in</Link>
            </div>
        </div>
    </div>
  );
};

export default Register;
